import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import "../App.css";
import ProductImg1 from "../Assets/product-images/product-still-ruled-transparent.png";
import SageGif from "../Assets/Sage_Spin.gif";
// import ProductImages from '../Assets/product-images/'
import { Button, Typography as ReactTypography } from "@mui/material";
import ColorPalette from "../ColorPalette";
import Counter from "../Components/Counter";
import AltCTAComponent from "../Components/AltCTAComponent";
import { Navigate } from "react-router-dom";

export default function Home(): JSX.Element {
  const x = 1;

  return (
    <>
      <AppContainer className="__AppContainer">
        {/* <div style={{backgroundColor}}></div> */}
        {/* give me a div styled like a card with a white backgroud */}
        <StyledHeaderCard>
          <div
            style={{
              zIndex: "1",
              margin: "0 auto 0 0px",
              maxWidth: "75%",
              textAlign: "left",
              fontFamily: "Courier New, monospace",
            }}
          >
            <Typography
              variant="h1"
              style={{
                // zIndex: "1",
                // margin: "0 auto 0 0px",
                // maxWidth: "70%",
                // textAlign: "left",
                fontSize: "3rem",
                fontWeight: "600",
                fontFamily: "Courier New, monospace",
              }}
            >
              Unlock Your Writting <br /> Unleash Your Creativity <br /> with
              Sage
            </Typography>
            <a href="/home">
              <StyledButton
                variant="contained"
                type="submit"
                onClick={() => <Navigate to="/home" />}
              >
                Get on the waitlist
              </StyledButton>
            </a>
          </div>
          <div style={{ width: "5px" }}>
            <StyledImg src={ProductImg1} />
          </div>
        </StyledHeaderCard>

        <StyledAboutCard>
          Welcome to the future of seamless note-taking. Our advanced digital
          solution, Sage Paper, combines the best of handwritten and digital
          note-keeping, simplifying your creative process and organization.
          Immerse yourself in a focused writing environment devoid of the
          distractions of other apps or internet browsing, while indulging in
          the tactile pleasure of traditional writing.
          <br />
          <br />
          Sage Paper consolidates your ideas, sketches, and notes into a single
          device, promising easy retrieval and enhanced collaboration. From
          students to professionals, everyone can benefit from the efficiency
          and ease brought by this revolutionary writing tool.
          <br />
          <br />
          By addressing the common problems associated with traditional
          note-taking methods and existing writing apps, Sage Paper aims to
          provide a comprehensive and efficient solution for individuals seeking
          a seamless, integrated, and enhanced writing experience.
        </StyledAboutCard>

        <SolutionSection className="SolutionSection">
          <StyledSolutionRow className="SolutionRow">
            <StyledSolutionCard>
              <Typography variant="h4">Notes Consolidation</Typography>
              <Typography variant="body1">
                All your notebooks and handwritten notes in one electronic
                device.
              </Typography>
            </StyledSolutionCard>

            <StyledSolutionCard>
              <Typography variant="h4">Easy Retrieval</Typography>
              <Typography variant="body1">
                Save, organize, and search your notes effortlessly.
              </Typography>
            </StyledSolutionCard>

            <StyledSolutionCard>
              <Typography variant="h4">Tactile Writing</Typography>
              <Typography variant="body1">
                Experience the familiarity of pen and paper on a digital
                platform.
              </Typography>
            </StyledSolutionCard>
          </StyledSolutionRow>

          <StyledSolutionRow className="SolutionRow">
            <StyledSolutionCard>
              <Typography variant="h4">Real-time Collaboration</Typography>
              <Typography variant="body1">
                Seamless connectivity for instant sharing and engagement.
              </Typography>
            </StyledSolutionCard>

            <StyledSolutionCard>
              <Typography variant="h4">Built-in Memory</Typography>
              <Typography variant="body1">
                Never lose your important notes or progress again.
              </Typography>
            </StyledSolutionCard>

            <StyledSolutionCard>
              <Typography variant="h4">Advanced Features</Typography>
              <Typography variant="body1">
                Pressure sensitivity, sketching capabilities, and more.
              </Typography>
            </StyledSolutionCard>
          </StyledSolutionRow>
        </SolutionSection>

        <StyledCard>
          <img src={SageGif} alt="gif" width="50%" />
        </StyledCard>

        <StyledSection style={{ margin: "0 auto" }}>
          <a href="/home">
            <StyledButton
              variant="outlined"
              type="submit"
              onClick={() => <Navigate to="/home" />}
            >
              Get on the waitlist
            </StyledButton>
          </a>
        </StyledSection>

        <StyledSection>
          <Counter number={19} />
        </StyledSection>

        {/* <AltCTAComponent signedUpForWaitList={false} /> */}
      </AppContainer>
    </>
  );
}

// const AppContainer = styled.div`
//   width: 100%;
//   min-height: 100vh;
//   /* background-color: #ebebeb; */
//   /* background-color: #ebebeb; */
//   /* background-color: white; */

//   background: linear-gradient(65deg, #d9dbdd, #d9dbdd, #ebebeb);
//   display: flex;
//   flex-direction: column;
//   padding: 20px;
//   font-family: "Courier New", monospace;
// `;

const StyledButton = styled(Button)`
  &.MuiButton-root {
    background: ${ColorPalette.txt1};
    border-radius: 5px;
    /* border: 2px solid ${ColorPalette.txt1}; */
    /* color: palevioletred; */
    color: white;
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 0.25em 1em;

    :hover {
      background-color: transparent;
    }
  }
`;

const StyledImg = styled.img`
  margin-left: auto;
  /* max-width: 50%; */
  width: 400px;
  position: relative;
  right: 32vw;
  z-index: 0;
`;

const Typography = styled(ReactTypography)`
  font-family: "Courier New", monospace;
`;

// const StyledSection = styled.div`
//   width: 80%;
//   margin: 5px auto;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   overflow: hidden;
//   border-radius: 4px;
//   padding: 8px;
//   z-index: 2;
// `;

const StyledCard = styled.div`
  /* background-color: white; */
  /* background-color: #ebebeb; */
  background-color: none;

  /* box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.1); */
  box-shadow: none;

  width: 80%;
  min-height: 200px;
  margin: 5px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 4px;
  padding: 8px;
  z-index: 2;
`;

const StyledHeaderCard = styled(StyledCard)`
  background-color: transparent;
  box-shadow: none;
  /* color: #289b3c; */
  color: rgba(100, 196, 94, 0.9);
`;

const StyledAboutCard = styled(StyledCard)`
  /* background-color: ${ColorPalette.bg3}; */
  /* color: ${ColorPalette.txt1}; */
  box-shadow: none;
  flex-direction: column;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0px 1px 20px -10px rgba(0, 0, 0, 0.2);
`;

const SolutionSection = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* align-content: center; */
  /* justify-content: center; */
  margin: 0 auto;
  padding: 0;
`;

// const StyledSolutionRow = styled.div`
//   display: flex;
//   justify-content: space-between;
//   width: 100%;
//   margin: 25px auto 50px auto;
//   gap: 50px;
//   z-index: 3;
// `;

// const StyledSolutionCard = styled(StyledCard)`
//   display: flex;
//   flex-direction: column;
//   max-width: calc(85% / 3);
//   text-align: left;
//   padding: 0 8px;

//   background-color: ${ColorPalette.txt1};
//   color: white;

//   .MuiTypography-root {
//     font-family: "Courier New", monospace;
//   }

//   .MuiTypography-h4 {
//     margin-bottom: 15px;
//   }
// `;

// From gpt
const StyledSolutionCard = styled(StyledCard)`
  display: flex;
  flex-direction: column;
  max-width: calc(85% / 3);
  text-align: left;
  padding: 0 8px;

  background-color: ${ColorPalette.txt1};
  color: white;

  .MuiTypography-root {
    font-family: "Courier New", monospace;
  }

  .MuiTypography-h4 {
    margin-bottom: 15px;
  }

  @media (max-width: 768px) {
    /* styles for screens less than or equal to 768px wide */
    max-width: 100%;
    margin-bottom: 20px;
    font-size: 1.5rem;
  }
`;

const StyledSolutionRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 25px auto 50px auto;
  gap: 50px;
  z-index: 3;

  @media (max-width: 768px) {
    /* styles for screens less than or equal to 768px wide */
    flex-direction: column;
    align-items: center;
  }
`;

const StyledSection = styled.div`
  width: 80%;
  margin: 5px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 4px;
  padding: 8px;
  z-index: 2;

  @media (max-width: 768px) {
    /* styles for screens less than or equal to 768px wide */
    width: 90%;
  }
`;

const AppContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(65deg, #d9dbdd, #d9dbdd, #ebebeb);
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-family: "Courier New", monospace;

  @media (max-width: 768px) {
    /* styles for screens less than or equal to 768px wide */
    padding: 10px;
  }

  * > a {
    text-decoration: none;
  }
`;
