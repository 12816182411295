import styled from 'styled-components';

export default function Counter({ number }: { number?: number }) {
  const stringNumber = number ? number?.toString() : '0';
  const WaitListCount = stringNumber.padStart(6, '0');
  return (
    <StyledCounterInner>
      {WaitListCount.split('').map((c, idx) => {
        const key = `${idx}-${c}-key`;
        return <StyledCounterNumber key={key}>{c}</StyledCounterNumber>;
      })}
    </StyledCounterInner>
  );
}

const StyledCounterInner = styled.div`
  background-color: #555555;
  max-width: 175px;
  align-content: center;
  display: flex;
  margin: auto;
  margin-top: 24px;
  text-align: center;
  justify-content: center;
`;
const StyledCounterNumber = styled.div`
  width: 20px;
  background-color: #888888;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  padding: 3px;
  margin: 1.5px;
  font-family: verdana;
  vertical-align: center;
`;
