import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import "./index.css";
import Home from "./Pages/Home";
import Home2 from "./Pages/Home2";
// import Feedback from "./Pages/Feedback";
import OurStory from "./Pages/OurStory";
import Deck from "./Pages/Deck";

const errorElement = <Navigate to="/" />;

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/home" />,
    errorElement,
  },
  {
    path: "/home",
    element: <Home />,
    errorElement,
  },
  {
    path: "/about",
    element: <Home2 />,
    errorElement,
  },
  // {
  //   path: "/feedback",
  //   element: <Feedback />,
  //   errorElement,
  // },
  {
    path: "/our-story",
    element: <OurStory />,
    errorElement,
  },
  {
    path: "/deck",
    element: <Deck />,
    errorElement,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "100vw",
        overflow: "hidden",
      }}
    >
      <RouterProvider router={router} />
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
