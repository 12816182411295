import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import SageLogo from '../Assets/thinksagelogo.png';
import ColorPalette from '../ColorPalette';

export default function NavBar({
  homeIconBgColor,
  homeIconBorderColor,
  navBrColor,
  homeButtonUrl,
}: {
  homeIconBgColor?: string;
  homeIconBorderColor?: string;
  navBrColor?: string;
  homeButtonUrl?: string;
}): JSX.Element {
  return (
    <>
      <NavContainer className="NavContainer">
        <SageLogoHomeIconContainer
          to={homeButtonUrl || '/home'}
          className="SageLogoHomeIconContainer"
          bgcolor={homeIconBgColor}
          bordercolor={homeIconBorderColor}
        >
          <SageLogoHomeIcon className="SageLogoHomeIcon" src={SageLogo} />
        </SageLogoHomeIconContainer>
      </NavContainer>
      <NavBr color={navBrColor} />
    </>
  );
}

const NavContainer = styled.div`
  padding: 8px 12px 6px;
  display: flex;
  text-align: center;
  height: 40px;
`;

const NavBr = styled.div.attrs((props?: any) => ({
  color: props.color || 'rgba(255, 255, 255, 0.8)',
}))`
  background-color: ${(props) => props.color};
  border-radius: 100px;
  height: 0.5px;
  width: 95%;
  margin: auto;
  margin-bottom: 25px;
`;

const SageLogoHomeIconContainer = styled(NavLink).attrs((props?: any) => ({
  bgcolor: props.bgcolor || `${ColorPalette.bg1}`,
  bordercolor: props.bordercolor || 'white',
}))`
  margin: auto;
  background-color: ${(props) => props.bgcolor};
  border-color: ${(props) => props.bordercolor};
  border-style: solid;
  border-width: 1px;
  border-radius: 100px;
  padding: 2px;

  top: 0;
  position: relative;
  z-index: 100;
`;

const SageLogoHomeIcon = styled.img`
  height: 50px;
  width: 50px;
`;
