/*
  https://dev.to/gedalyakrycer/create-an-email-list-with-react-mailchimp-965
 */

import React, { useState, useEffect } from 'react';
import '../App.css';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { TextField, Button, Typography } from '@mui/material';
// import FormControl, { useFormControl } from "@mui/material/FormControl";
import styled from 'styled-components';
import ColorPalette from '../ColorPalette';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';
import Filter from 'bad-words';
import AltCTAComponent from './AltCTAComponent';

export default function MailchimpSignupForm({
  onSignup,
}: {
  onSignup?: () => void;
}) {
  const u_value = process.env.REACT_APP_MC_U_VALUE;
  const id_value = process.env.REACT_APP_MC_ID_VALUE;
  const f_id_value = process.env.REACT_APP_MC_F_ID_VALUE;
  const postUrl = `https://forwardvines.us11.list-manage.com/subscribe/post?u=${u_value}&amp;id=${id_value}&amp;f_id=${f_id_value}`;
  const { width, height } = useWindowSize();

  const CustomForm: any = ({ status, message, onValidated }: any) => {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [showRequiredFieldsErr, setShowRequiredFieldsErr] = useState(false);
    const [showProfanityErr, setShowProfanityErr] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);

    const handleSignup = (e: any) => {
      e.preventDefault();

      if (!email || email === '' || !firstName || firstName === '') {
        setShowRequiredFieldsErr(true);
        setShowProfanityErr(false);
        return;
      } else {
        setShowRequiredFieldsErr(false);
      }

      const filter = new Filter();
      const isProfane =
        filter.isProfane(firstName) || filter.isProfane(lastName);
      if (isProfane) {
        setShowProfanityErr(true);
        setShowRequiredFieldsErr(false);
        return;
      } else {
        setShowProfanityErr(false);
      }

      email &&
        firstName &&
        email.indexOf('@') > -1 &&
        onValidated({
          EMAIL: email,
          PHONE: phoneNumber.replaceAll(/[+-.\s]/g, ''),
          MERGE1: firstName.trim(),
          MERGE2: lastName.trim(),
          tags: '9322921',
        });
    };

    const clearFields = () => {
      setFirstName('');
      setLastName('');
      setEmail('');
      setShowRequiredFieldsErr(false);
    };

    useEffect(() => {
      if (status === 'success') {
        clearFields();
        setShowConfetti(true);
        !!onSignup && onSignup();
      }
    }, [status]);

    return (
      <>
        {showConfetti && (
          <Confetti
            tweenDuration={7000}
            numberOfPieces={400}
            gravity={0.15}
            wind={0.01}
            initialVelocityY={{ min: 8, max: 3 }}
            recycle={false}
            onConfettiComplete={() => setShowConfetti(false)}
            width={width}
            height={height}
          />
        )}
        <form className="mc__form">
          {/* Status Messages */}
          {status === 'sending' && (
            <div className="mc__alert mc__alert--sending">sending...</div>
          )}
          {status === 'error' && (
            <div
              className="mc__alert mc__alert--error"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
          {status === 'success' && (
            // <div
            //   className="mc__alert mc__alert--success"
            //   dangerouslySetInnerHTML={{ __html: message }}
            // />
            <div
              className="mc__alert mc__alert--success"
              style={{
                background: 'rgb(234, 235, 237)',
                padding: '12px',
                borderRadius: '12px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography variant="body1" style={{ fontWeight: '700' }}>
                You've been added to the waitlist!
              </Typography>
              {/* <Typography
                variant="subtitle1"
                style={{
                  fontWeight: "600",
                  marginBottom: "15px",
                  fontStyle: "italic",
                }}
              >
                Share it with a friend!
              </Typography> */}

              <AltCTAComponent signedUpForWaitList={true} />
            </div>
          )}

          <div>
            <Typography variant="caption">
              {showRequiredFieldsErr && 'First name and email required'}
              {showProfanityErr &&
                'This name could not be used, please try a new one.'}
              &nbsp;
            </Typography>
          </div>

          {status !== 'success' && (
            <StyledFormActionsSection className="__StyledFormActionsSection">
              <StyledTextInputSection className="_StyledTextInputSection">
                <StyledNameSection className="__StyledNameSection">
                  <StyledNameTextField
                    className="__StyledNameTextField"
                    label="First Name"
                    variant="filled"
                    InputProps={{ disableUnderline: true, required: true }}
                    onChange={(e: any) => setFirstName(e.target.value)}
                    type="text"
                    value={firstName}
                    placeholder="Jane"
                    required
                  />

                  <StyledNameTextField
                    className="__StyledNameTextField"
                    label="Last Name"
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    onChange={(e: any) => setLastName(e.target.value)}
                    type="text"
                    value={lastName}
                    placeholder="Doe"
                  />
                </StyledNameSection>

                <StyledContactSection>
                  <StyledEmailTextField
                    className="__StyledEmailTextField"
                    label="Email"
                    variant="filled"
                    InputProps={{ disableUnderline: true, required: true }}
                    onChange={(e: any) => setEmail(e.target.value)}
                    type="email"
                    value={email}
                    placeholder="your@email.com"
                    required
                  />

                  <StyledPhoneTextField
                    className="__StyledPhoneTextField"
                    label="Phone"
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    onChange={(e: any) => setPhoneNumber(e.target.value)}
                    type="tel"
                    value={phoneNumber}
                    placeholder="xxx-xxx-xxxx"
                  />
                </StyledContactSection>
              </StyledTextInputSection>

              <StyledButton
                variant="contained"
                type="submit"
                onClick={handleSignup}
              >
                Get on the waitlist
              </StyledButton>
            </StyledFormActionsSection>
          )}
        </form>
      </>
    );
  };

  return (
    <div className="mc__form-container">
      <MailchimpSubscribe
        url={postUrl}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={(formData: any) => subscribe(formData)}
          />
        )}
      />
    </div>
  );
}

const StyledFormActionsSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

const StyledTextInputSection = styled.div``;

const StyledNameSection = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  width: 100%;
`;

const StyledContactSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const StyledTextField = styled(TextField)`
  background: rgb(250, 248, 250);
  color: black;
  border-radius: 12px;

  label {
    &.Mui-focused {
      color: ${ColorPalette.txt1};
    }
    border-radius: 12px;
  }

  .MuiFilledInput-root {
    background: transparent !important;
  }

  .MuiFilledInput-input {
    background: rgba(247, 246, 248, 0.9);
    border-radius: 12px;
  }

  input.my-input:-internal-autofill-selected {
    background-color: rgb(255, 255, 255) !important;
    background-image: none !important;
    color: rgb(0, 0, 0) !important;
  }
`;

const StyledNameTextField = styled(StyledTextField)``;

const StyledEmailTextField = styled(StyledTextField)`
  width: 100%;
`;
const StyledPhoneTextField = styled(StyledTextField)`
  width: 100%;
`;

const StyledButton = styled(Button)`
  &.MuiButton-root {
    background: ${ColorPalette.txt1};
    border-radius: 12px;
    /* border: 2px solid ${ColorPalette.txt1}; */
    /* color: palevioletred; */
    color: white;
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 0.25em 1em;

    :hover {
      background-color: transparent;
    }
  }
`;
