import NavBar from '../Components/NavBar';
import styled from 'styled-components';
import ColorPalette from '../ColorPalette';

export default function OurStory(): JSX.Element {
  const t = '';
  return (
    <div className="Home">
      <AppContainer className="__AppContainer">
        <NavBar homeButtonUrl="/feedback" />
        <BodyContainer className="BodyContainer">
          <h2
            style={{
              width: '100%',
              textAlign: 'center',
              // fontFamily: "Montserrat,sans-serif",
            }}
          >
            Our Story
          </h2>

          <div
            style={{
              textAlign: 'left',
              marginBottom: '20px',
            }}
          >
            Welcome to Sage Paper, the future of seamless note-taking. Our
            advanced digital solution combines the best of handwritten and
            digital note-keeping, simplifying your creative process and
            organization. Sage Paper consolidates your ideas, sketches, and
            notes into a single device, promising easy retrieval and enhanced
            collaboration.
          </div>
          <div
            style={{
              display: 'flex',
              margin: 'auto',
              textAlign: 'center',
              aspectRatio: '16 / 9',
            }}
          >
            <iframe
              title="Sage Paper Video"
              src="https://www.youtube.com/embed/lNCTf7a7Gn4?&amp;autoplay=1"
              width="100%"
              height="auto"
              allowFullScreen
            ></iframe>
          </div>
        </BodyContainer>
      </AppContainer>
    </div>
  );
}

const AppContainer = styled.div`
  /* https://www.canva.com/colors/color-wheel/ */
  /* background: #0c0d15; */
  /* background: linear-gradient(
    ${ColorPalette.bg1},
    ${ColorPalette.bg1},
    ${ColorPalette.bg2}
  ); */
  width: 100%;
  min-height: 100vh;
  /* color: ${ColorPalette.txt1}; */
  /* color: #bebb8e; */

  background: linear-gradient(
    to right,
    ${ColorPalette.bg1},
    ${ColorPalette.bg2}
  );
  font-family: "monospace";
`;

const BodyContainer = styled.div`
  padding-left: 40px;
  padding-right: 40px;
`;
