const ColorPalette = {
  /* v1 styles
  txt1: "#d7d5b9",
  bg1: "#2a4937",
  bg2: "#000000",
  */

  txt1: '#289B3B',
  bg1: 'rgb(245,243,245)', // f5f3f5
  bg2: '#F0EFF0',
  bg3: '#E0E1E3',
};

export default ColorPalette;
