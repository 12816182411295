import NavBar from "../Components/NavBar";
import styled from "styled-components";
import ColorPalette from "../ColorPalette";

import React from "react";
import { Typography } from "@mui/material";

const CanvaDeck = () => (
  <div>
    <Container>
      <StyledIframe
        loading="lazy"
        src="https://www.canva.com/design/DAFmyNgIAhk/view?embed"
        allowFullScreen={true}
        allow="fullscreen"
      />
    </Container>
    <div style={{ display: "flex" }}>
      <ExternalLink href="https://www.canva.com/design/DAFmyNgIAhk/view?utm_content=DAFmyNgIAhk&amp;utm_campaign=designshare&amp;utm_medium=embeds&amp;utm_source=link">
        <Typography variant="caption">Link to presentation</Typography>
      </ExternalLink>
    </div>
  </div>
);

export default function OurStory(): JSX.Element {
  const t = "";
  return (
    <div className="Home">
      <AppContainer className="__AppContainer">
        <NavBar homeButtonUrl="/feedback" />
        <BodyContainer className="BodyContainer">
          <CanvaDeck />
        </BodyContainer>
      </AppContainer>
    </div>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  padding-bottom: 0;
  box-shadow: 0 2px 8px 0 rgba(63, 69, 81, 0.16);
  margin-top: 1.6em;
  margin-bottom: 0.9em;
  overflow: hidden;
  border-radius: 8px;
  will-change: transform;
`;

const StyledIframe = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: none;
  padding: 0;
  margin: 0;
`;

const ExternalLink = styled.a`
  target: _blank;
  rel: noopener;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;

const AppContainer = styled.div`
  /* https://www.canva.com/colors/color-wheel/ */
  /* background: #0c0d15; */
  /* background: linear-gradient(
    ${ColorPalette.bg1},
    ${ColorPalette.bg1},
    ${ColorPalette.bg2}
  ); */
  width: 100%;
  min-height: 100vh;
  /* color: ${ColorPalette.txt1}; */
  /* color: #bebb8e; */

  background: linear-gradient(
    to right,
    ${ColorPalette.bg1},
    ${ColorPalette.bg2}
  );
  font-family: "monospace";
`;

const BodyContainer = styled.div`
  padding-left: 40px;
  padding-right: 40px;
`;
