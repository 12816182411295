/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import '../App.css';
import MailchimpSignupForm from '../Components/MailchimpSignupForm';
import ColorPalette from '../ColorPalette';
import NavBar from '../Components/NavBar';
import AltCTAComponent from '../Components/AltCTAComponent';
import Counter from '../Components/Counter';
import countapi from 'countapi-js';

export default function Home(): JSX.Element {
  // https://medium.com/@mignunez/how-to-count-the-number-of-visits-on-your-website-with-html-css-javascript-and-the-count-api-2f99b42b5990
  // https://api.countapi.xyz/create?namespace=sagepaper.org&enable_reset=1
  // https://api.countapi.xyz/hit/sagepaper.org/a18b9dfc-cfa4-4ac3-b552-8d79162af163?callback=websiteVisits
  // {"namespace":"sagepaper.org","key":"a18b9dfc-cfa4-4ac3-b552-8d79162af163","value":0}
  const counterApiNamespace = 'sagepaper.org';
  // const counterApiKey =
  //   `${counterApiNamespace}-home-` + window.location.hostname?.toString();
  const CURRENT_COUNT = 18;
  const [signupCount, setSignUpCount] = useState(CURRENT_COUNT);

  const dropBoxVideoLink = '/rg37rcuwgnh3z12/SagePromoClip.mp4?dl=0';
  const homeVideoRef: any = useRef();

  const [signedUpForWaitList, setSignedUpForWaitList] = useState(false);

  useEffect(() => {
    if (homeVideoRef?.click) {
      homeVideoRef?.click();
    }
    countapi
      .get(counterApiNamespace, 'a18b9dfc-cfa4-4ac3-b552-8d79162af163')
      .then((result) => {
        result.value && setSignUpCount(result.value);
      });
  });

  const handleSignup = async () => {
    let newSignUp =
      !signedUpForWaitList ||
      localStorage.getItem('signedUpForWaitList') !== 'true';

    if (newSignUp) {
      newSignUp = false;
      await countapi.hit(
        counterApiNamespace,
        'a18b9dfc-cfa4-4ac3-b552-8d79162af163',
      );
    }

    localStorage.setItem('signedUpForWaitList', 'true');
    setSignedUpForWaitList(true);
  };

  return (
    <div className="Home">
      <AppContainer className="__AppContainer">
        <NavBar homeButtonUrl="/about" />
        <BodyContainer className="BodyContainer">
          <StyledCenterSection>
            <div style={{ height: '100px' }}>
              {/* <StyledHeader variant="h3">Sage Paper</StyledHeader>
              <StyledCaption variant="subtitle1">
                Your favorite journal. Your only Journal
              </StyledCaption> */}
              {/* <div>The future of writing</div> */}
            </div>

            {/* <img src={reactlogo} className="App-logo" alt="logo" /> */}
            {/* <img src={sageSideView} alt="logo" /> */}
            <div
              style={{
                width: '100vw',
                height: '100vh',
                overflow: 'clip',
                position: 'fixed',
                top: '0',
                zIndex: '-3',
              }}
            >
              <StyledVideoContainier className="VideoContainer">
                <StyledVideo
                  className="home_video"
                  id="home_video"
                  loop
                  autoPlay
                  muted
                  playsInline
                  ref={homeVideoRef}
                >
                  <source
                    src={`https://dl.dropboxusercontent.com/s${dropBoxVideoLink}`}
                    type="video/mp4"
                  ></source>
                </StyledVideo>
              </StyledVideoContainier>
            </div>

            <StyledSignupWaitlistForm className="__StyledSignupWaitlistForm">
              <MailchimpSignupForm onSignup={handleSignup} />
            </StyledSignupWaitlistForm>

            {!signedUpForWaitList && (
              <AltCTAComponent signedUpForWaitList={signedUpForWaitList} />
            )}
          </StyledCenterSection>

          <Counter
            number={signedUpForWaitList ? signupCount + 1 : signupCount}
          />
        </BodyContainer>
      </AppContainer>
    </div>
  );
}

const AppContainer = styled.div`
  /* https://www.canva.com/colors/color-wheel/ */
  /* background: #0c0d15; */
  /* background: linear-gradient(
    ${ColorPalette.bg1},
    ${ColorPalette.bg1},
    ${ColorPalette.bg2}
  ); */
  width: 100%;
  min-height: 100vh;
  color: ${ColorPalette.txt1};
  /* color: #bebb8e; */

  /* background: linear-gradient(
    to right,
    ${ColorPalette.bg1},
    ${ColorPalette.bg2}
  ); */

  background: transparent;
`;

const BodyContainer = styled.div`
  padding-left: 40px;
  padding-right: 40px;
`;

const StyledVideoContainier = styled.div`
  position: absolute;
  top: 0;
  width: 190vw;
  height: 100vh;
  left: 0;
  display: flex;
`;

const StyledVideo = styled.video`
  object-fit: cover;
  overflow: hidden;
`;

const StyledCenterSection = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

// const StyledHeader = styled(Typography)`
//   &.MuiTypography-root {
//     font-family: serif;
//   }
// `;

// const StyledCaption = styled(Typography)`
//   &.MuiTypography-root {
//     margin-bottom: 10px;
//     font-family: serif;
//     font-style: italic;
//   }
// `;

const StyledSignupWaitlistForm = styled.div`
  margin-top: 20px;
`;
